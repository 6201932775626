<template>
  <div class="px-4">
    <p class="text-center font-bold text-xl mb-10">{{ title }}</p>
    <carousel
      v-if="items.length > 0"
      :perPageCustom="[
        [320, 1],
        [680, 2],
        [768, 2],
        [1024, 3]
      ]"
      paginationColor="#adadad"
      paginationActiveColor="#228cff"
      :paginationSize="30"
    >
      <slide v-for="item in items" :key="item.id">
        <div
          class=" rtl  flex flex-col justify-center align-center rounded-sm shadow m-3 max-w-md p-4"
        >
          <div>
            <p class="font-bold">الكلمات المفتاحية:</p>
            <div class="py-3">
              <span
                class="custom-keyword rounded-sm px-3 bg-gray-500 text-white"
                v-for="keyword in item.replyTo"
                :key="keyword"
                >{{ keyword }}</span
              >
            </div>
          </div>
          <div>
            <p class="font-bold">محتوى الرسالة الخاصة:</p>
            <p v-if="item.message.type === 'textTemplate'" class="py-3">
              {{ item.message.data.message | summarize }}
            </p>
            <p v-else class="py-3">TEMPLATE</p>
          </div>
          <div class="my-3">
            <p
              class="py-3 flex content-center items-center"
              :class="{ 'text-red-500': !item.welcom, 'text-green-500': item.welcom }"
            >
              <font-awesome-icon icon="at" />
              الإجابة على "المنشنز"
            </p>
            <p
              class="py-3 flex content-center items-center"
              :class="{ 'text-red-500': !item.tags, 'text-green-500': item.tags }"
            >
              <font-awesome-icon icon="undo" />
              الرد بتعليق ترحيبي
            </p>
          </div>
          <div class="ltr flex justify-center">
            <button class="mujeeb-button red" @click="$emit('removeItem', item.id)">
              <font-awesome-icon icon="trash-alt" /> حذف
            </button>
            <button class="mujeeb-button" @click="$emit('editItem', item)">
              <font-awesome-icon icon="edit" /> تعديل
            </button>
          </div>
        </div>
      </slide>
    </carousel>
    <div v-else class="text-center">
      لم يتم إضافة أي إجابة
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {},
  filters: {
    summarize: function(value) {
      if (!value) return "";
      if (value.length < 40) return value;
      return value.substring(0, 40) + " ...";
    }
  }
};
</script>

<style lang="scss" scoped>
svg {
  margin-left: 10px;
}
.custom-keyword {
  margin: 0px 5px;
  &:first-child {
    margin-left: 0px;
  }
}
.mujeeb-button {
  &.icon-only {
    i {
      margin: 0px;
    }
  }
}
</style>
