<template>
  <loading-spinner position="absolute" v-if="loading"></loading-spinner>
  <div v-else class="m-wrapper">
    <div class="p-5">
      <div class="action-field rtl mb-0">
        <div class="m-field-label">
          <label class="label">نسبة الإجابة على التعليقات</label>
          <div class="m-tooltip">
            <img
              class="big-tabs__help-icon"
              src="../../assets/svg/icon.svg"
              alt="Info icon"
              width="15"
              height="15"
            />
            <span class="tooltip-content">
              تحديد نسبة الكومنتات، 0 يعني عدم الرد على اي تعليق بكومنت ترحيبي 100 الرد على كل
              التعليقات
            </span>
          </div>
        </div>
        <div class="m-field-input">
          <vue-slider
            :included="true"
            style="padding: 0px;"
            v-model="responseRate"
            :adsorb="true"
            :marks="[0, 10, 25, 33, 50, 100]"
            :height="12"
            :dotSize="30"
            direction="rtl"
            :contained="true"
          >
            <template v-slot:label="{ active, value }">
              <div :class="['vue-slider-mark-label', 'custom-label', { active }]">{{ value }}%</div>
            </template>
            <template v-slot:tooltip="{ value }">
              <div class="custom-tooltip">{{ value }}%</div>
            </template>
          </vue-slider>
        </div>
      </div>
      <p class="rtl lg:mt-2 sm:mt-8 mt-10">{{ readableRate }}</p>
    </div>

    <div class="flex justify-center mt-10">
      <button class="mujeeb-button green big" @click="saveSettings">حفظ</button>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
const readable = {
  0: "لن يتم الرد على أي تعليق بتعليق ترحيبي",
  100: "سيتم الإجابة على جميع التعليقات بتعليق ترحيبي",
  50: "سيتم الإجابة على نصف عدد التعليقات بتعليق ترحيبي",
  33: "سيتم الإجابة على ثلث عدد التعليقات بتعليق ترحيبي",
  25: "سيتم الإجابة على ربع عدد التعليقات بتعليق ترحيبي",
  10: "سيتم الإجابة على 10 بالمائة من عدد التعليقات بتعليق ترحيبي"
};
export default {
  components: {
    VueSlider
  },
  data() {
    return {
      responseRate: 100,
      loading: false
    };
  },
  methods: {
    saveSettings() {
      const values = { 0: 0, 100: 1, 50: 2, 33: 3, 25: 4, 10: 10 };
      console.log(values[this.responseRate]);
      this.loading = true;
      this.$api
        .customRequest({
          method: "post",
          url: `/services/reply/users/${this.$store.getters["user/id"]}/pages/${
            this.$store.getters["page/id"]
          }/comments-rate/${values[this.responseRate]}`
        })
        .then(({ data }) => {
          if (data.success) {
            console.log("success");
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    readableRate() {
      return readable[this.responseRate];
    }
  },
  created() {
    this.loading = true;
    const values = { 0: 0, 1: 100, 2: 50, 3: 33, 4: 25, 10: 10 };
    this.$api
      .customRequest({
        method: "get",
        url: `/services/reply/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/comments-rate`
      })
      .then(({ data }) => {
        if (data.success) {
          this.responseRate = values[data.rate];
        }
      })
      .catch(err => {
        if (err.response.status === 404 && err.response.data && err.response.data.success) {
          this.responseRate = 100;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>

<style lang="scss" scoped>
.vue-slider {
  padding: 0px;
}
</style>
