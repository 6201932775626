<template>
  <div>
    <async-state-modal
      v-if="show_async_operation_state"
      position="absolute"
      :state="async_operation_state"
      :text="state_text"
    ></async-state-modal>
    <loading-spinner v-if="loading" position="absolute" :text="loadingText"></loading-spinner>
    <div v-else class="commenter-activation-dialog">
      <div class="section-1" v-if="step === 1">
        <div class="text-center">
          <p class="mb-4 text-xl font-bold">
            أولاً اختر منشوراً
          </p>
          <p class="text-lg">
            هنا يتم اختيار المنشور المراد تفعيل الإجابات التلقائية عليه
          </p>
        </div>

        <p class="text-center text-lg m-10">اختر منشورا من متصفح المنشورات</p>

        <div class="action-field rtl">
          <div class="m-field-label">
            <label class="label">اختر من منشورات الصفحة:</label>
            <div class="m-tooltip">
              <img
                class="big-tabs__help-icon"
                src="../../assets/svg/icon.svg"
                alt="Info icon"
                width="15"
                height="15"
              />
              <span class="tooltip-content"
                >'هنا يمكنك انتقاء منشور من منشورات الصفحة ساواء المدولة أو المنشورة مسبقا '</span
              >
            </div>
          </div>
          <div class="m-field-input ltr flex justify-end">
            <button class="mujeeb-button icon-on-left big" @click="showBrowserDialog = true">
              اختر منشوراً
            </button>
          </div>
        </div>

        <p class="text-center text-lg m-10">
          <span class="font-bold">أو</span> ضع هنا رابط المنشور الذي تود تفعيل الخدمة عليه
        </p>

        <div class="action-field rtl">
          <div class="m-field-label">
            <label class="label">رابط المنشور:</label>
            <div class="m-tooltip">
              <img
                class="big-tabs__help-icon"
                src="../../assets/svg/icon.svg"
                alt="Info icon"
                width="15"
                height="15"
              />
              <span class="tooltip-content"
                >'هنا يمكنك وضع رابط المنشور، على أن يكون رابطاً زمنيا.'</span
              >
            </div>
          </div>
          <div class="m-field-input ltr">
            <b-input v-model="$v.url.$model" @input="onUrlInput"></b-input>
          </div>
        </div>

        <postsBrowser
          v-if="showBrowserDialog"
          @cancel="showBrowserDialog = false"
          @select="setPostUrl"
        ></postsBrowser>
      </div>

      <div class="section-2" v-if="step === 2">
        <div>
          <carousel
            title="الإجابة على الأسئلة التي تحتوي كلمات مفتاحية محددة"
            :items="customIntents"
            @removeItem="onDeleteReply('custom', $event)"
            @editItem="onEditClickded('custom', $event)"
          >
          </carousel>
          <div class="flex justify-center mt-5">
            <button class="mujeeb-button icon-on-right big" @click="openReplyModal('custom')">
              <font-awesome-icon icon="plus" /> إضافة إجابة مخصصة
            </button>
          </div>
        </div>

        <hr />

        <div class="mb-12">
          <div class="flex flex-col justify-center items-center content-center">
            <p class="text-center font-bold text-xl mb-10">الإجابة على جميع الأسئلة</p>
            <defaultReplyCard
              v-if="defaultReply"
              :defaultReply="defaultReply"
              @removeDefaultReply="onDeleteReply('default')"
              @editDefaultReply="onEditClickded('default')"
            >
            </defaultReplyCard>
            <p v-else class="text-center">
              لم يتم إضافة إجابة عامة
            </p>
          </div>
          <div class="flex justify-center mt-5" v-if="defaultReply == null">
            <button class="mujeeb-button icon-on-right big" @click="openReplyModal('default')">
              <font-awesome-icon icon="plus" /> إضافة إجابة عامة
            </button>
          </div>
        </div>

        <newCustomReplyModal
          v-if="showReplyModal"
          :reply_prop="stagedReply"
          :modal="true"
          :reply_type_prop="inputReplyType"
          @close="closeReplyModal"
          @submitNewReply="addNewReply"
          @SUBMIT_REPLY="editReply"
        >
        </newCustomReplyModal>
      </div>

      <div class="dialog-controller">
        <button class="mujeeb-button icon-on-right green big" v-if="step == 2" @click="submit">
          <font-awesome-icon icon="check" /> تفعيل
        </button>
        <button class="mujeeb-button icon-on-right green big" v-if="step < 2" @click="nextStep">
          <font-awesome-icon icon="chevron-left" />التالي
        </button>
        <button class="mujeeb-button icon-on-left big" v-if="step > 1" @click="previousStep">
          السابق<font-awesome-icon icon="chevron-right" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import postsBrowser from "../utils/postsBrowserDialog";
import { required, url } from "vuelidate/lib/validators";
import carousel from "./utils/carousel";
import defaultReplyCard from "./utils/defaultReplycard";
import newCustomReplyModal from "./utils/customReplyModal_new";
const isTrue = value => typeof value === "boolean" && value === true;

export default {
  components: {
    postsBrowser,
    carousel,
    defaultReplyCard,
    newCustomReplyModal
  },
  data() {
    return {
      step: 1,
      showBrowserDialog: false,
      loading: false,
      loadingText: null,
      postId: null,
      url: null,
      validUrl: false,
      customIntents: [],
      defaultReply: null,
      // NEW
      inputReplyType: null,
      showReplyModal: false,
      stagedReply: null
    };
  },
  methods: {
    /**
     * This function is called when the user selects a post from the browseDialog
     * @function updatePostUrl
     * @param {Object} post - The selected post.
     * @param {string} post.id - The post id.
     * @param {boolean} post.is_published - Indicator that the post is published or not.
     * @param {string} post.message - The post content.
     * @param {string} post.permalink_url - The post url.
     * @param {string} post.scheduled_publish_time - The post scheduled or published time in seconds.
     */
    setPostUrl(post) {
      this.$v.url.$model = post.permalink_url;
      this.showBrowserDialog = false;
      // console.log("this post is ", post);
      // this.chehckUrl();
      this.showState({ state: true, text: "تم التحقق من رابط المنشور" });
      this.$v.validUrl.$model = true;
      this.$v.postId.$model = post.id;
      this.step = 2;
    },
    chehckUrl() {
      this.loadingText = "يتم التحقق من الرابط...";
      this.loading = true;
      if (this.url) {
        this.$api
          .customRequest({
            url: `/services/reply/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/posts/checkurl`,
            method: "post",
            responseType: "json",
            data: {
              userId: this.$store.getters["user/id"],
              postUrl: this.url,
              pageId: this.$store.getters["page/id"]
            }
          })
          .then(({ data }) => {
            const result = data.check;
            if (result) {
              this.showState({ state: true, text: "تم التحقق من رابط المنشور" });
              this.$v.validUrl.$model = true;
              this.$v.postId.$model = data.postId;
              this.step = 2;
            } else {
              this.$v.validUrl.$model = false;
              this.$v.url.$model = null;
              this.showState({ state: false, text: "المنشور المختار غير صالح" });
            }
          })
          .catch(err => {
            console.log(err);
            this.$v.validUrl.$model = false;
            this.$v.url.$model = null;
            this.$buefy.toast.open({
              duration: 5000,
              queue: false,
              message: `حدث خطأ أثناء التأكد من المنشور`,
              position: "is-top",
              type: "is-danger"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    nextStep() {
      if (!this.$v.validUrl.$invalid && !this.$v.url.$invalid) {
        this.step += 1;
      } else {
        if (this.$v.url.$invalid) {
          this.$buefy.toast.open({
            duration: 2500,
            queue: true,
            message: `الرجاء اختيار منشور أولاً`,
            position: "is-top",
            type: "is-danger"
          });
        } else if (this.$v.validUrl.$invalid) {
          this.$buefy.toast.open({
            duration: 2500,
            queue: true,
            message: `الرجاء التأكد من رابط المنشور`,
            position: "is-top",
            type: "is-danger"
          });
        }
        this.resetReply();
      }
    },
    previousStep() {
      this.step -= 1;
    },
    resetReply() {
      this.url = null;
      this.validUrl = false;
    },
    submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$buefy.toast.open({
          duration: 5000,
          queue: false,
          message: "يجب تعبئة الحقول الفارغة",
          position: "is-top",
          type: "is-danger"
        });
      } else if (
        (this.customIntents != null && this.customIntents.length > 0) ||
        this.defaultReply != null
      ) {
        this.loadingText = "يتم تفعيل الإجابة التلقائية";
        this.loading = true;
        this.$api
          .customRequest({
            url: `/services/reply/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}`,
            method: "post",
            data: {
              customIntents: this.customIntents,
              default: this.defaultReply,
              url: this.url,
              postId: this.postId
            },
            responseType: "json"
          })
          .then(({ data }) => {
            this.resetState();
            this.$buefy.toast.open({
              duration: 5000,
              queue: false,
              message: `تم تفعيل الإجابة`,
              position: "is-top",
              type: "is-success"
            });
          })
          .catch(err => {
            if (err.response.data) {
              this.$buefy.toast.open({
                duration: 5000,
                queue: false,
                message: err.response.data.reasons[0].msg,
                position: "is-top",
                type: "is-danger"
              });
            }
          })
          .finally(() => {
            this.loadingText = "";
            this.loading = false;
          });
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          queue: false,
          message: "يجب إدخال رد واحد على الأقل",
          position: "is-top",
          type: "is-danger"
        });
      }
    },
    onUrlInput: debounce(function(value) {
      if (value) {
        this.chehckUrl();
      }
    }, 1500),
    openReplyModal(replyType) {
      if (replyType === "custom") {
        this.stagedReply = null;
        this.inputReplyType = "custom";
        this.showReplyModal = true;
      } else {
        this.stagedReply = this.defaultReply;
        this.inputReplyType = "default";
        this.showReplyModal = true;
      }
    },
    resetState() {
      this.step = 1;
      this.showBrowserDialog = false;
      this.loading = false;
      this.loadingText = null;
      this.postId = null;
      this.url = null;
      this.validUrl = false;
      this.customIntents = [];

      this.defaultReply = null;
    },
    // NEW
    closeReplyModal() {
      this.showReplyModal = false;
      this.inputReplyType = null;
    },
    addNewReply(reply) {
      if (this.inputReplyType === "custom") {
        this.customIntents.push({ ...reply, id: this.$randomId(6) });
      } else {
        this.defaultReply = reply;
      }
      this.inputReplyType = null;
      this.showReplyModal = false;
    },
    onEditClickded(replyType, reply) {
      if (replyType === "default") {
        this.stagedReply = this.defaultReply;
      } else {
        this.stagedReply = reply;
      }
      this.inputReplyType = replyType;
      this.showReplyModal = true;
    },
    onDeleteReply(replyType, id) {
      if (replyType === "default") {
        this.defaultReply = null;
      } else {
        const index = this.customIntents.findIndex(item => item.id === id);
        if (index !== -1) {
          this.$delete(this.customIntents, index);
        }
      }
      this.stagedReply = null;
    },
    editReply(payLoad) {
      this.showReplyModal = false;
      if (payLoad.action === "new") {
        if (payLoad.type === "custom") {
          this.customIntents.push({ ...payLoad.data, id: this.$randomId(6) });
        } else {
          this.defaultReply = payLoad.data;
        }
      } else {
        if (payLoad.type === "custom") {
          const index = this.customIntents.findIndex(item => item.id === payLoad.id);
          if (index !== -1) {
            this.$set(this.customIntents, index, { ...payLoad.data, id: payLoad.id });
          }
        } else {
          this.defaultReply = payLoad.data;
        }
      }
      this.showReplyModal = false;
    }
  },
  watch: {
    url(newUrl, oldUrl) {
      if (oldUrl) {
        if (newUrl !== oldUrl) {
          this.validUrl = false;
        }
      }
    }
  },
  created() {
    console.log("inside add commenter");
  },
  errorCaptured: function(err, component, details) {
    alert(err);
    console.log(details);
    console.log(component);
  },
  validations: {
    url: {
      required
    },
    validUrl: {
      isTrue
    },
    postId: {
      required
    }
  }
};
</script>

<style lang="scss" scoped>
hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

.commenter-activation-dialog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  hr {
    height: 2px;
  }
  .section-1 {
    width: 100%;
    height: 100%;
  }
  .section-2 {
    width: 100%;
    height: 100%;
  }
  .dialog-controller {
    margin: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    button {
      margin: 10px;
    }
  }
}
</style>
