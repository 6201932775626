<template>
  <div class="">
    <loading-spinner position="absolute" v-if="loading"></loading-spinner>
    <TemplateModal
      v-if="showTemplateModal"
      :template="stagedTemplate"
      @close-modal="showTemplateModal = false"
      @template-added="saveTemplate('add', $event)"
      @template-edited="saveTemplate('edit', $event)"
      @template-done="saveTemplate($event)"
    ></TemplateModal>
    <div class="rtl">
      <b-field label="بحث">
        <b-input placeholder="أدخل اسم القالب للبحث "></b-input>
      </b-field>
    </div>
    <div class="mt-16 mb-10 rtl">
      <button class="mujeeb-button big green" @click="addNewTemplate">إضافة قالب جديد</button>
    </div>
    <div class="template-container">
      <div class="m-items-table rtl" v-if="templates != null && templates.length > 0">
        <div class="thead">
          <div class="tr">
            <div
              class="th"
              v-for="(property, index) in templateProperties"
              :class="getCssClass(property.cssClass)"
              :key="index"
            >
              {{ property.title }}
            </div>
          </div>
        </div>

        <div class="tbody">
          <div class="tr" v-for="(template, index) in templates" :key="template.id">
            <div
              class="td"
              v-for="(property, index) in templateProperties"
              :key="index"
              :data="property.title"
              :class="getCssClass(property.cssClass)"
            >
              <span v-if="property.name === 'name'">{{ template.name }}</span>
              <span v-else-if="property.name === 'sentences_count'">{{
                template[property.name]
              }}</span>
              <span v-else-if="property.name === 'last_modified'">{{
                template[property.name] | makeItReadable
              }}</span>
              <template v-else>
                <button class="m-edit" data="تعديل" @click="editTemplate(template)">
                  <font-awesome-icon icon="edit" />
                </button>
                <button class="m-delete" data="حذف" @click="confirmDeletion(template)">
                  <font-awesome-icon icon="trash-alt" />
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!loading" class="empty-table-msg">
        <p>أدخل قالبك الأول من خلال الضغط على زر "إضافة قالب جديد"</p>
      </div>
      <div class="pagination-control">
        <button
          class="mujeeb-button"
          v-if="paginationStates[paginationState].next"
          @click="fetchPage"
        >
          التالي<font-awesome-icon class="left-margin" icon="chevron-right" />
        </button>
        <button
          class="mujeeb-button"
          v-if="paginationStates[paginationState].back"
          @click="fetchPage(false)"
        >
          <font-awesome-icon class="right-margin" icon="chevron-left" /> السابق
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateModal from "./utils/Templates_templateModal";
import PaginationMixin from "./../../mixins/pagination_2";
import moment from "moment";
export default {
  mixins: [
    PaginationMixin(`/services/reply/.../comment-template`, "templates", "last_modified", "desc")
  ],
  components: {
    TemplateModal
  },
  data() {
    return {
      loading: false,
      templates: [],
      templateProperties: [
        {
          title: "اسم القالب",
          name: "name"
        },
        {
          title: "الإنشاء/التعديل",
          name: "last_modified"
        },
        {
          title: "عدد الجمل",
          name: "sentences_count"
        },
        {
          title: "خيارات",
          cssClass: "control-container"
        }
      ],
      showTemplateModal: false
    };
  },
  methods: {
    addNewTemplate() {
      this.stagedTemplate = null;
      this.showTemplateModal = true;
    },
    getCssClass(cssClass) {
      const temp = {};
      temp[`item-width-${this.templateProperties.length}`] = true;
      if (cssClass) {
        temp[cssClass] = true;
      }
      return temp;
    },
    editTemplate(template) {
      this.stagedTemplate = template;
      this.showTemplateModal = true;
    },
    confirmDeletion(template) {
      this.$buefy.dialog.confirm({
        title: `حذف القالب `,
        message: ` ${template.name}  هل انت متاكد من رغبتك بحذف القالب ذو الاسم`,
        confirmText: "متأكد",
        cancelText: "كلا",
        type: "is-success",
        onConfirm: () => {
          this.loading = true;
          return this.$api
            .customRequest({
              method: "delete",
              url: `/services/reply/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/comment-template/${template.id}`
            })
            .then(({ data }) => {
              console.log(data);
              this.removeTemplate(template);
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    removeTemplate(template) {
      const index = this.templates.findIndex(temp => {
        return temp.id === template.id;
      });
      if (index !== -1) {
        this.templates.splice(index, 1);
      }
    },
    saveTemplate(newTemplateData) {
      this.loading = true;
      this.stagedTemplate = null;
      const url =
        newTemplateData.operation === "edit"
          ? `/services/reply/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/comment-template/${newTemplateData.id}`
          : `/services/reply/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/comment-template`;
      const method = newTemplateData.operation === "edit" ? "put" : "post";
      this.showTemplateModal = false;
      console.log(newTemplateData);
      this.$api
        .customRequest({
          method: method,
          url: url,
          data: {
            name: newTemplateData.name,
            sentences: newTemplateData.sentences
          }
        })
        .then(({ data }) => {
          if (newTemplateData.operation === "add") {
            this.templates.push(data.created_template);
          } else {
            const index = this.templates.findIndex(t => t.id === data.created_template.id);
            if (index !== -1) {
              this.templates.splice(index, 1, data.created_template);
            } else {
              // show error message
            }
          }
          let message =
            newTemplateData.operation === "edit"
              ? `تمت عملية التحديث بنجاح`
              : `تمت عملية الإضافة بنجاح `;
          this.$buefy.toast.open({
            duration: 3000,
            message,
            position: "is-top",
            type: "is-success"
          });
        })
        .catch(err => {
          let message =
            newTemplateData.operation === "edit"
              ? `حدث خطأ أثناء عملية التعديل`
              : `حدث خطأ أثناء عملية الإضافة`;
          console.log(err);
          this.$buefy.toast.open({
            duration: 3000,
            message,
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  filters: {
    makeItReadable: function(value) {
      if (value) {
        return moment(value)
          .utc()
          .format("DD/MM/YYYY");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
