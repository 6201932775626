<template>
  <div class="rtl flex flex-col justify-center align-center rounded-sm shadow m-3 max-w-md p-4">
    <div>
      <p class="font-bold">محتوى الرسالة:</p>
      <p v-if="defaultReply.message.type === 'textTemplate'" class="py-3">
        {{ defaultReply.message.data.message }}
      </p>
      <p v-else class="py-3">TEMPLATE</p>
    </div>
    <div class="my-3">
      <p
        class="py-3 flex content-center items-center"
        :class="{ 'text-red-500': !defaultReply.tags, 'text-green-500': defaultReply.tags }"
      >
        <font-awesome-icon icon="at" />
        وضع على "المنشنز"
      </p>
      <p
        class="py-3 flex content-center items-center"
        :class="{ 'text-red-500': !defaultReply.welcom, 'text-green-500': defaultReply.welcom }"
      >
        <font-awesome-icon icon="undo" />
        وضع بتعليق ترحيبي
      </p>
    </div>
    <div class="ltr flex justify-center">
      <button class="mujeeb-button red" @click="$emit('removeDefaultReply')">
        <font-awesome-icon icon="trash-alt" /> حذف
      </button>
      <button class="mujeeb-button" @click="$emit('editDefaultReply')">
        <font-awesome-icon icon="edit" /> تعديل
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "default-reply-card",
  props: {
    defaultReply: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
svg {
  margin-left: 10px;
}
</style>
