<template>
  <div class="m-commenter-section">
    <div class="m-wrapper">
      <div class="taps-container">
        <div class="m-tab-nav rounded-corners">
          <router-link
            v-for="link in links"
            :to="{ name: link.href }"
            exact
            :key="link.name"
            tag="div"
            class="m-tab rounded-corners"
          >
            <span>{{ link.name }}</span>
            <font-awesome-icon :icon="link.icon" />
          </router-link>
        </div>
      </div>
      <router-view class="router-container"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          name: "التحكم",
          href: "Commenter.CommentsControl",
          icon: "cog"
        },
        {
          name: "قوالب التعليقات",
          href: "Commenter.CommentsTemplate",
          icon: "comments"
        },
        {
          name: "تصفح الإجابات",
          href: "Commenter.BrowseComments",
          icon: "list-alt"
        },
        {
          name: "إضافة إجابة",
          href: "Commenter.NewCommenter",
          icon: "plus"
        }
      ],
      steps: [
        {
          step: "اختر منشورا",
          active: true
        },
        {
          step: "ادخل الإجابات",
          active: false
        },
        {
          step: "تخصيص",
          active: false
        }
      ]
    };
  },
  beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.
    if (to.name === "Dashboard.Commenter") {
      next({ name: "Commenter.NewCommenter" });
    } else {
      next();
    }
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    if (to.name === "Dashboard.Commenter") {
      next({ name: "Commenter.NewCommenter" });
    } else {
      next();
    }
  }
};
</script>

<style scoped lang="scss">
* {
  font-family: cairo;
}
// .m-wrapper {
//   position: relative;
//   height: 100;
//   bottom: 0;
//   top: 0;
//   padding: 20px;
//   padding-bottom: 50px;
// }

.m-commenter-section {
  display: flex;
  flex-direction: column;
  .taps-container {
    flex: 0 0 auto;
  }
  .router-container {
    flex: 1 1 auto;
  }
}

.right-aligned-section {
  display: flex;
  justify-content: flex-end;
}
</style>
