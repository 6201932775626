<template>
  <div class="m-modal-componenet">
    <div class="m-modal-componenet__content rounded-corners">
      <div class="taps-container" style=" direction: ltr;">
        <div class="m-tab-nav rounded-corners">
          <div
            v-for="tab in tabs"
            :key="tab.name"
            class="m-tab rounded-corners"
            v-bind:class="{ 'router-link-active': tab.isActive }"
            @click="changeTabTo(tab.name)"
          >
            <font-awesome-icon :icon="tab.icon" />
            <span>{{ tab.name }}</span>
          </div>
        </div>
      </div>

      <b-table
        :data="scheduledPosts"
        :loading="loading"
        :mobile-cards="true"
        v-if="tabs[0].isActive"
        ref="scheduled-table"
      >
        <template slot-scope="props">
          <b-table-column label="تاريخ النشر" centered>
            <span class="tag" :class="type(props.row.is_published)">
              {{
                props.row.hasOwnProperty("scheduled_publish_time")
                  ? formatDate(props.row.scheduled_publish_time)
                  : "published"
              }}
            </span>
          </b-table-column>

          <b-table-column label="صور" centered>
            <img class="post-img" :src="props.row.picture" alt="" />
          </b-table-column>

          <b-table-column label="رابط المنشور" centered>
            <a
              class="button select-butto rounded-corners"
              :href="props.row.permalink_url"
              target="_blank"
            >
              اذهب إلى المنشور
            </a>
          </b-table-column>

          <b-table-column label="محتوى المنشور" centered>
            {{
              props.row.hasOwnProperty("message")
                ? props.row.message
                : "" | truncateWithEllipses(101)
            }}
          </b-table-column>

          <b-table-column label="" centered>
            <button class="button select-butto rounded-corners" @click="selectPost(props.row)">
              اختر هذا المنشور
            </button>
          </b-table-column>
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
              </p>
              <p>لا يوجد منشورات</p>
            </div>
          </section>
        </template>
      </b-table>

      <b-table
        :data="publishedPosts.slice(0, 15)"
        :loading="loading"
        :mobile-cards="true"
        v-else-if="tabs[1].isActive && !competition"
        ref="published-table"
      >
        <template slot-scope="props">
          <b-table-column label="تاريخ النشر" centered>
            <span class="tag" :class="type(props.row.is_published)">
              {{
                props.row.hasOwnProperty("scheduled_publish_time")
                  ? formatDate(props.row.scheduled_publish_time)
                  : "published"
              }}
            </span>
          </b-table-column>

          <b-table-column label="صور" centered>
            <img class="post-img" :src="props.row.picture" alt="" />
          </b-table-column>

          <b-table-column label="رابط المنشور" centered>
            <!-- <a :href="props.row.permalink_url" target="_blank"> {{ props.row.permalink_url }} </a> -->
            <a
              class="button select-butto rounded-corners"
              :href="props.row.permalink_url"
              target="_blank"
            >
              اذهب إلى المنشور
            </a>
          </b-table-column>

          <b-table-column label="محتوى المنشور" centered>
            {{
              props.row.hasOwnProperty("message")
                ? props.row.message
                : "" | truncateWithEllipses(101)
            }}
          </b-table-column>

          <b-table-column label="" centered>
            <button class="button select-butto rounded-corners" @click="selectPost(props.row)">
              اختر هذا المنشور
            </button>
          </b-table-column>
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
              </p>
              <p>لا يوجد منشورات</p>
            </div>
          </section>
        </template>
      </b-table>

      <div v-else-if="tabs[1].isActive && competition" class="competiton-info">
        <p>
          يجب عليك اختيار منشور مجدول حصراً
        </p>
      </div>

      <div class="content__action">
        <button class="mujeeb-button green big" @click="cancel">
          إلغاء
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    styling: {
      type: String
    },
    scheduled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    competition: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  data() {
    return {
      selected: null,
      loading: false,
      defaultOpenedDetails: [1],
      scheduledPosts: [],
      publishedPosts: [],
      tabs: [
        {
          name: "المنشورات المجدولة",
          isActive: false,
          icon: "clock"
        },
        {
          name: "المنشورات",
          isActive: true,
          icon: "eye"
        }
      ]
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    type(value) {
      switch (value) {
        case true:
          return "is-danger";
        case false:
          return "is-success";
      }
    },
    formatDate(date) {
      const momentDate = moment(date * 1000).local();
      var hours = momentDate.hour();
      var minutes = momentDate.minute();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12 // eslint-disable-line
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        momentDate.month() + 1 + "/" + momentDate.date() + "/" + momentDate.year() + " " + strTime
      );
    },
    selectPost(post) {
      this.$emit("select", post);
    },
    changeTabTo(name) {
      switch (name) {
        case "المنشورات المجدولة":
          this.tabs[0].isActive = true;
          this.tabs[1].isActive = false;
          break;
        case "المنشورات":
          this.tabs[1].isActive = true;
          this.tabs[0].isActive = false;
          break;
      }
    },
    /**
     * @function fetchScheduledPosts
     * @description fetch schedualed posts from the selected page.
     * @param {Object} vuex_instance
     */
    fetchScheduledPosts() {
      this.loading = true;
      const options = {
        // url: `/getscheduledposts`,
        url: `/services/posts/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/scheduled`,
        method: "get",
        responseType: "json",
        timeout: 6000,
      };
      this.$api
        .customRequest(options)
        .then(response => {
          this.loading = false;
          this.scheduledPosts = response.data.data;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    /**
     * @function fetchPublishedPosts
     * @description fetch published posts from the selected page.
     * @param {Object} vuex_instance
     */
    fetchPublishedPosts() {
      this.loading = true;
      const options = {
        // url: `/getposts`,
        url: `/services/posts/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/published`,
        method: "get",
        // state.user.accessToken
        responseType: "json",
        timeout: 6000,
      };
      this.$api
        .customRequest(options)
        .then(response => {
          this.loading = false;
          this.publishedPosts = response.data.data;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  filters: {
    truncateWithEllipses: function(text, limit) {
      return text.length > limit ? `${text.substring(0, 101)} ...` : text;
    }
  },
  created() {
    this.fetchPublishedPosts();
    this.fetchScheduledPosts();
  }
};
</script>

<style lang="scss" scoped>
.m-tab {
  svg {
    margin-right: 10px !important;
    margin-left: 0px !important;
  }
}
.m-modal-componenet {
  z-index: 1005;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}

.m-modal-componenet__content {
  display: flex;
  background: white;
  padding: 15px;
  height: 80vh;
  width: 95vw;
  margin: 2.5vw;
  flex-direction: column;
  justify-content: space-around;
}

.m-modal-componenet__content .b-table,
.competiton-info {
  overflow-y: auto;
  height: calc(100% - 132px);
  direction: ltr;
  border: 1px solid #c8c8c8;
  border-radius: 6px;
  padding: 5px;
}

.competiton-info {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 25px;
  text-align: center;
}

.content__action {
  height: 60px;
  text-align: center;
}

.table.is-hoverable tbody tr:not(.is-selected):hover {
  background-color: #1ea5ff55;
}

.post-img {
  height: 50px;
  width: auto;
}

.action__cancel-button {
  background: #ff4348;
  color: white;
  margin-top: 20px;
  padding: 0 45px;
}

/* @media only screen and (max-width: 450px) {
  * {
    font-size: 14px;
  }
} */

.right-aligned-section {
  display: flex;
  justify-content: flex-start;
  height: 42px;
  margin-bottom: 30px;
}
</style>
