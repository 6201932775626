<template>
  <div
    class="overflow-y-auto fixed top-0 right-0 bottom-0 left-0 w-full h-full flex justify-center align-center items-center bg-transparant-black z-10"
  >
    <div
      class="reply-input-modal text-right block reply-modal rounded-sm shadow bg-white p-8 small:w-5/6 md:w-11/12 overflow-y-auto m-auto"
    >
      <p class="text-center font-bold text-xl mb-10">{{ template ? "تعديل القالب" : "إضافة قالب جديد" }}</p>
      <div>
        <div class="mb-10">
          <p class="rtl mb-1">اختر اسم القالب:</p>
          <input class="rtl input" ref="sentence-input" v-model="name" placeholder="ترحيب">
        </div>
      </div>
      
      <div class="mb-10">
          <!-- <p class="rtl mb-1">إضغط زر Enter	&#8207  لإضافة الجملة إلى مجموعة جمل القالب</p> -->
          <p class="rtl mb-1">اضغط زر Enter &#8207 لإضافة الجملة إلى مجموعة جمل القالب <strong> يجب إضافة خمسة جُمل</strong></p>
          <!-- <b-input
            class=""
            ref="sentence-input"
            v-model="sentence"
            ellipsis
            placeholder="أدخل الجملة التي ترغب بالرد بها"
            @beforeinput="workAroundForEnter"
            @keydown.native="addNewSentence"
          >
          </b-input> -->
          <input 
          class="input"
          type="text" 
          ref="sentence-input" 
          placeholder="أدخل الجملة التي ترغب بالرد بها"
          v-model="sentence" 
          @keydown="addNewSentence"
          @blur="addNewSentenceAtBlur"
          >
          <button class="mujeeb-button" @click="addUserName">اسم صاحب التعليق<font-awesome-icon icon="user" class="ml-2" size="sm"/></button>
        
        </div>

      <div class="mt-5" v-if="sentences.length > 0">
        <p class="rtl mb-5 font-bold">الجمل المدخلة:</p>
        <div class="flex flex-wrap rtl">
          <span v-for="(sentence, index) in sentences" :key="sentence" class="inlin-block m-1 p-2 rounded-md bg-gray-600 text-white font-bold"><font-awesome-icon class="ml-2 cursor-pointer" icon="times-circle" size="lg" @click="sentences.splice(index, 1)"/> {{ sentence }} </span>
        </div>
      </div>

      <div v-if="sentences.length === 0" class="border-dashed rounded-md border border-gray-500 mt-8 mb-8 p-2 flex justify-center">
        <p class="font-bold">لم يتم إدخال أيَّة جمل حتى الآن</p>
      </div>

      <div class="flex justify-center align-center items-center mt-10">
        <button class="mujeeb-button big" @click="$emit('close-modal')">إغلاق</button>
        <button class="mujeeb-button big green" @click="saveTemplate" v-if="!$v.$invalid">{{ template ? "تعديل" : "إضافة" }}</button>
      </div>

    </div>
  </div>
</template>

<script>
import { required, minLength  } from "vuelidate/lib/validators";
export default {
  props: {
    template: {
      type: Object
    }
  },
  data() {
    return {
      sentence: "",
      sentences: [],
      name: "",
      templateProperties: [
        "name",
        "sentences",
      ]
    };
  },
  methods: {
    addUserName() {
      // this.inputRef.setAttribute("value", this.inputRef.value + "{{NAME}}");
      this.sentence = this.sentence + " {{NAME}} ";
      this.$refs["sentence-input"].focus();
    },
    // workAroundForEnter(e) {
    //   if(e.inputType === 'deleteContentBackward') {
    //     // check whatever you want to check for
    //     // console.log()
    //     // if(e.keyCode === 13)
    //     e.preventDefault() // will block the deletion
    // }
    // },
    addNewSentence(event) {
      console.log(event)
      console.log("keydown")
      console.log(event.keyCode);
      console.log(event.key)
      const keyPressed = event.key || event.which || event.keyCode || 0;
      // event.preventDefault()
      if (event && (keyPressed === 13 || keyPressed === 'Enter') && this.sentence ) {
        console.log("enter was pressed");
        if (this.sentences.indexOf(this.sentence) === -1) {
          this.sentences.push(this.sentence);
          this.sentence = "";
        }
      }
    },
    addNewSentenceAtBlur(e) {
      const sentence = e.target.value
      console.log('OnBlur')
      if (sentence) {
        console.log('Adding sentence')
        if (this.sentences.indexOf(this.sentence) === -1) {
          this.sentences.push(this.sentence);
          this.sentence = "";
        }
      }
    },
    saveTemplate() {
      if (!this.$v.$invalid) {
        if (this.template) {
          this.$emit('template-done', { name: this.name, sentences: this.sentences, id: this.template.id, operation: 'edit' })
        } else {
          this.$emit('template-done', { name: this.name, sentences: this.sentences, operation: 'add' })
        }
      }
    }
  },
  created() {
    if (this.template) {
      const templateCopy = JSON.parse(JSON.stringify(this.template))
      for (let index = 0; index < this.templateProperties.length; index++) {
        const tP = this.templateProperties[index];
        this[tP] = templateCopy[tP];
      }
    }
  },
  validations: {
    name: {
      required
    },
    sentences: {
      required,
      minLength: minLength(1)
    }
  }
};
</script>

<style lang="scss" scoped></style>
